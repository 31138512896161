.modal-template-main{
    width: 100dvw;
    height: 100dvh;
    background-color: var(--background-primary);
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: stretch;
    align-items: stretch;
    align-content: stretch;
    animation-duration: 450ms;
    animation-name: slidein;
}

.modal-template-goback{
    flex-shrink: 0;
}

.modal-template-content{
    flex-grow: 1;
    height: calc(100dvh - var(--nav-height));
    overflow-x: hidden;
    overflow-y: auto;
}