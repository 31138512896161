.button-ladder-permit{
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    padding: 0px 23px;
}

.button-ladder-permit p{
    margin: 0;
    margin-left: 9px;
    font-size: 20px;
    color: white;
}