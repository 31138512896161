.badges-main{
    position: absolute;
    background-color: white;
    color: black;
    border-radius: 1em;
}

.badges-main p{
    margin-block: 0.25em;
    margin-inline: 0.5em;
}