.stepladder-main{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    animation: var(--animation-show-in);
    height: 100dvh;

    overflow: hidden;
    background-color: var(--background-primary);
    flex-wrap: nowrap;
}

.stepladder-content{
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: flex-start;
    margin-bottom: 25px;
    margin-left: 7%;
    margin-right: 7%;
    gap: 2em;
    animation-duration: 450ms;
}

.stepladder-separator{
    margin-top: 20px;
    margin-bottom: 20px;
    align-self: stretch;
    height: 20px;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction: row;
    flex-wrap: nowrap;
    text-align: center;
}

.stepladder-separator p{
    margin-left: 10px;
    margin-right: 10px;
}

.stepladder-separator::after, .stepladder-separator::before{
    content: " ";
    width: 100%;
    height: 1px;
    background-color: #a8a8a8;
    margin-top: 20px;
    margin-bottom: 20px;
    align-self: stretch;
    margin: 0;
    transform: translateY(10px);
}

.stepladder-button{
    height: 165px;
    overflow: hidden;
    background-color: white;
    align-self: stretch;
    border-radius: 20px;
    box-shadow: 0px 3px 6px #00000029;
    display: grid;
    grid-template-columns: [col1] 180px [col2] 1fr;
    grid-template-rows: [row1] 25% [row2] 25% [row3] 25% [row3] 25%;
    padding: 0;
    border: 0;
}

.stepladder-button-img{
    grid-column: 1;
    grid-row: 1 / span 4;
    height: 100%;
}

.stepladder-button-fade{
    background: transparent linear-gradient(90deg, #FFFFFF00 0px,#FFFFFF 190px,#FFFFFF 100%) 0% 0% no-repeat padding-box;
    grid-column: 1 / span 3;
    grid-row: 1 / span 4;
    border-radius: 20px;
    margin: 0;
    align-self: stretch;
    justify-self: stretch;
}

.stepladder-button-text{
    font-size: 20px;
    grid-column: 2;
    grid-row: 2;
    justify-content: end;
    align-self:end;
    text-align: right;
    margin: 0;
    margin-right: 10px;
}

.stepladder-button-info{
    font-size: 10px;
    grid-column: 2;
    grid-row: 3;
    justify-content: start;
    align-self:stretch;
    text-align: right;
    margin: 0;
    margin-right: 10px;
}

.stepladder-section{
    margin: 0;
    margin-top: 20px;
    font-weight: normal;
    text-align: left;
    font-size: 40px;
    color: black;
}

.stepladder-question-main{
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    justify-items: center;
    align-self: center;
    gap: 2em;
    background-color: white;
    padding-inline: 1em;
    padding-block: 2em;
    margin-inline: 0.5em;
    border-radius: 10px;
    box-shadow: 0px 3px 6px #00000029;
}

.stepladder-question-txt{
    font-size: x-large;
    margin: 0;
}

.stepladder-question-button{
    width: 30%;
    height: 40px;
    border: 0;
    color: white;
    font-size: large;
}

.stepladder-question-button-container{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 1em;
    justify-content: center;
    align-items: center;
}

.stepladder-stepper-container{
    animation-duration: 450ms;
    animation-name: showIn;
    justify-self: stretch;
    align-self: stretch;
    height: 100%;
}

.stepper-finish-container{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100dvh - var(--nav-height));
    margin-inline: 0.5em;
    gap: 1.2em;

    animation-duration: 0.5s;
    animation-name: fadeInFromBottom;
}

.stepper-finish-container h1{
    font-size: x-large;
    text-align: center;
    text-decoration: underline;
    font-weight: bold;
}

.stepper-finish-container p{
    font-size: large;
    text-align: left;
    margin: 0;
}

.stepper-finish-container p::before{
    content: '▶️';
    padding-right: 0.5em;
}

.stepper-finish-container h2{
    font-size: large;
    text-align: center;
}

.stepper-finish-img-container{
    display: grid;
    justify-content: center;
    align-items: center;
    justify-items: center;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
    width: 100px;
    height: 100px;
    overflow: hidden;
    align-self: center;
    margin: 1em;
    padding: 1em;

    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 3px 6px #00000029;
}

.stepper-finish-img-container img{
    grid-column: 1;
    grid-row: 1;
    object-fit: cover;
    animation-delay: 0.5s;
    animation-duration: 800ms;
}

.stepper-finish-button{
    width: 60%;
    padding-block: 1em;
    margin-block: 1em;
    border: 0;
    color: white;
    font-size: large;
    align-self: center;
    background-color: #3DBFF1;

    animation-name: fadeInFromBottom;
    animation-delay: 2s;
    animation-duration: 450ms;
}

@keyframes fadeInFromTop {
    from{
        opacity: 0;
        transform: translateY(-25%);
    }
    50%{
        opacity: 0;
        transform: translateY(-25%);
    }
    to{
        opacity: 1;
        transform: translateY(0%);
    }
}

@keyframes fadeOutToTop {
    from{
        opacity: 1;
        transform: translateY(0%);
    }
    to{
        opacity: 0;
        transform: translateY(-50%);
    }
}

@keyframes fadeInFromBottom {
    from{
        opacity: 0;
        transform: translateY(50%);
    }
    to{
        opacity: 1;
        transform: translateY(0%);
    }
}

@keyframes fadeInFromCenter {
    from{
        opacity: 0;
        transform: scale(1.5);
    }
    to{
        opacity: 1;
        transform: scale(1);
    }
}