.stepper-main{
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
    height: 100%;

    overflow: hidden;
    background-color: var(--background-primary);
    flex-wrap: nowrap;

}

.stepper-header{
    flex-shrink: 0;
    height: 100px;
    display: grid;
    grid-template-columns: 33% 34% 33%;
    grid-template-rows: 100%;
    justify-items: start;
}

.stepper-line{
    height: 4px;
    background-color: #c2c2c2;
    border-radius: 5px;
    margin-top: 30px;
    justify-self: stretch;
    z-index: 0;

    grid-column: 1 / span 10;
    grid-row: 1;
}

.stepper-step-container{
    margin-top: 7px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    width: 30%;
    height: calc(100% - 7px);
    overflow: hidden;
    justify-self: center;
    z-index: 1;

    grid-row: 1;
    grid-column: 1 / span 10;
}

.stepper-step-circle{
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background-color: white;
    border: #5ACAF2 3px solid;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    color: #5ACAF2;
    font-weight: bold;
    font-size: x-large;
}

.stepper-step-circle-active{
    background-color: #5ACAF2;
    border-color: #5ACAF2;
    color: white;
}

.stepper-step-title{
    margin: 0;
    margin-top: 5px;
    text-align: center;
    color: #3DBFF1;
}

.stepper-body{
    flex-grow: 1;
    position: relative;
}

.stepper-content{
    width: 100%;
    height: 100%;
    display: flex;
}

.stepper-content-outside{
    position: absolute;
    top: 0;
    right: unset;
    width: 100%;
    height: 100%;
    display: flex;
    pointer-events: none;
    opacity: 0;
}

@keyframes slideRightToCenter {
    from{
        margin-left: 100%;
        opacity: 0;
    }

    to{
        margin-left: 0%;
        opacity: 1;
    }
}
  
@keyframes slideCenterToLeft {
    from{
        margin-right: 0%;
        opacity: 1;
    }

    to{
        margin-right: 100%;
        opacity: 0;
    }
}

@keyframes slideLeftToCenter {
    from{
        margin-right: 100%;
        opacity: 1;
    }

    to{
        margin-right: 0%;
        opacity: 0;
    }
}

@keyframes slideCenterToRight {
    from{
        margin-left: 0%;
        opacity: 0;
    }

    to{
        margin-left: 100%;
        opacity: 1;
    }
}
  

