:root{
  --background-primary: #EFEFEF;
  --nav-height: 73px;
  --animation-slide-in: slidein 0.5s ease-in-out;
  --animation-slide-out: slideout 0.5s ease-in-out;
  --animation-show-in: showIn 0.5s ease-in-out;
  --animation-show-out: showOut 0.5s ease-in-out;
}

body {
  height: 100dvh;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 100vw;
  font-family:'Segoe UI';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-primary);
  overflow: hidden;
  padding-bottom: env(safe-area-inset-bottom);
}

/* Ajoutez ce qui suit pour prendre en compte la safe area sur iOS */
@supports (padding: env(safe-area-inset)) or (height: calc(100vh - env(safe-area-inset-bottom))) {
  body {
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    height: calc(100vh - env(safe-area-inset-bottom));
  }
  .container-outside {
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    height: calc(100vh - env(safe-area-inset-bottom));
  }
}

.fill-to-screen{
  height: 100dvh;
}

@keyframes slidein {
  from{
    margin-left: 100%;
    width: 300%;
    opacity: 0;
  }

  to{
    margin-left: 0%;
    width: 100%;
    opacity: 1;
  }
}

@keyframes slideout {
  from{
    margin-left: 0%;
    width: 100%;
    opacity: 1;
  }

  to{
    margin-left: 100%;
    width: 300%;
    opacity: 0;
  }
}

@keyframes showIn {
  from{
      transform: translateX(100%);
      opacity: 0;
  }

  to{
      transform: translateX(0%);
      opacity: 1;
  }
}

@keyframes showOut {
  from{
      transform: translateX(0%);
      opacity: 1;
  }

  to{
      transform: translateX(-100%);
      opacity: 0;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a{
  text-decoration: none; 
  color: black;
}

.white-button{
  height: 70px;
  /* UI Properties */
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 20px;
  opacity: 1;
  border: 0;
  color: black;
  font-size: 20px;
}

.petra-button{
  box-shadow: 0px 0px 6px #85858580;
  border-radius: 20px;
  background-color: white;
  color: black;
  height: 70px;
}

.container-outside{
  z-index: 100000;
  height: 100dvh; /* utilise la hauteur dynamique de la fenêtre d'affichage (viewport) */
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 100vw;
  position: absolute;
  top: 0px;
  left: 0px;
  pointer-events: none;
}

.loading-container{
  justify-self: stretch;
  background-color: #a0a0a03b;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-element{
  width: 150px;
  height: 150px;
}
